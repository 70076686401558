import React from "react";
import {Link} from "react-router-dom";

import aelium_logo from "../../assets/aelium_logo_neg.png";

import "../../assets/css/logo-hover.css"

const Logo = () => (
    <Link id="go-home" className="sidebar-brand" to="/">
        <span className="align-middle me-3">
            <img src={aelium_logo} className="logo-hover" alt="Aelium logo" width="120"/>
        </span>
    </Link>
);

export default Logo;