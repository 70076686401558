import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {Col, Container, Row} from "react-bootstrap";

const Footer = ({isAuthenticated}) => (
    <footer className="footer">
        <Container className="fluid">
            <Row className="text-muted">
                <Col className="text-start">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <Link to="/privacy" className="text-muted">Privacy</Link>
                        </li>
                        <li className="list-inline-item">
                            <Link to="/tos" className="text-muted">Terms of Service</Link>
                        </li>
                        {
                            isAuthenticated && (
                                <li className="list-inline-item">
                                    <Link to="/messages?support=true" className="text-muted">Support</Link>
                                </li>
                            )
                        }
                    </ul>
                </Col>
                <Col xs={5} className="text-end">
                    <p className="mb-0">
                        &copy; 2024 - <a href="https://www.aelium.io" className="text-muted">Aelium srl</a>
                    </p>
                </Col>
            </Row>
        </Container>
    </footer>
);

Footer.propTypes = {
    isAuthenticated: PropTypes.bool,
};

export default Footer;