import React from "react";
import loading from "../assets/aelium_alfa.svg";

import "../assets/css/loading.css"

const Loading = () => (
    <div className="loading-container">
        <div className="spinner">
            <img src={loading} alt="Loading"/>
        </div>
        <div align="center">
            <div className="spinner-grow text-primary" role="status" style={{"animationDelay": '0s'}}>
            </div>
            <div className="spinner-grow text-primary" role="status" style={{"animationDelay": '.07s'}}>
            </div>
            <div className="spinner-grow text-primary" role="status" style={{"animationDelay": '.14s'}}>
            </div>
            <div className="spinner-grow text-primary" role="status" style={{"animationDelay": '.21s'}}>
            </div>
        </div>
    </div>
);

export default Loading;
