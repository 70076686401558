import React from "react";

import SidebarNavList from "./SidebarNavList";
import PropTypes from "prop-types";

const SidebarNavSection = (props) => {
  const { title, pages, license, ...rest } = props;

  return (
    <React.Fragment {...rest}>
      {title && <li className="sidebar-header">{title}</li>}
      <SidebarNavList pages={pages} depth={0} license={license}/>
    </React.Fragment>
  );
};

SidebarNavSection.propTypes = {
    title: PropTypes.string,
    pages: PropTypes.array,
    license: PropTypes.object
}

export default SidebarNavSection;
