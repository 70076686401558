import React from "react";
import {Link} from "react-router-dom";

const LogoHome = () => (
    <span className="d-inline-block d-sm-none">
        <Link id="go-home" to="/">
            <img className="avatar img-fluid rounded-circle" src="/aelium-logo.svg" alt="home"/>
        </Link>
    </span>
);

export default LogoHome;