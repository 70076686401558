import React, {Fragment, useEffect} from "react";
import PropTypes from 'prop-types';

import {useAuth0} from "@auth0/auth0-react";
import {Navbar, Nav} from "react-bootstrap";
import {SWRConfig} from "swr";

import SearchTopic from "./Search";
import Notifications from "./Notifications";
import Messages from "./Messages";
import User from "./User";
import Login from "./Login"
import SignUp from "./SignUp";
import {useHotkeys} from "react-hotkeys-hook";
import LogoHome from "./LogoHome";
import {useLocation} from "react-router-dom";
import useTheme from "../../utils/hooks/theme";


const NavBar = ({toggleSidebar, setToggleSidebar}) => {
    const { theme, setTheme } = useTheme()
    const location = useLocation();
    const {isAuthenticated} = useAuth0();
    useHotkeys('ctrl+`', () => setToggleSidebar(!toggleSidebar), {}, [toggleSidebar]);
    useHotkeys('ctrl+d', () => setTheme(theme === 'dark' ? 'default' : 'dark'), {}, [theme]);

    const toggle_sidebar = () => setToggleSidebar(!toggleSidebar)

    useEffect(() => {
        // on extra small screens close the sidebar when the location changes
        if (window.innerWidth < 768) {
            setToggleSidebar(true);
        }
    }, [location]);

    return (
        <Navbar className="navbar-bg">

            {isAuthenticated && (
                <Fragment>
                    <a id="sidebar-toggle" className="sidebar-toggle" onClick={() => toggle_sidebar()} >
                        <i className="hamburger align-self-center"/>
                    </a>

                    <LogoHome/>
                    <SearchTopic/>
                </Fragment>
            )}

            <Navbar.Collapse id="navbarScroll">
                <Nav className="navbar-nav navbar-align">

                    {isAuthenticated ? (
                        <Fragment>
                            <SWRConfig value={{refreshInterval: 10000}}>
                                <Notifications/>
                                <Messages/>
                            </SWRConfig>
                            <SWRConfig value={{revalidateOnFocus: false}}>
                                <User/>
                            </SWRConfig>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <SignUp/>
                            <Login/>
                        </Fragment>
                    )
                    }

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};

NavBar.propTypes = {
    toggleSidebar: PropTypes.bool,
    setToggleSidebar: PropTypes.func
}

export default NavBar;