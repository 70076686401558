import React, {Fragment} from "react";
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Dropdown,
    ListGroup,
    ListGroupItem,
    NavItem,
    NavLink
} from "react-bootstrap";
import {Link} from "react-router-dom";
import TimeAgo from "react-timeago";

const NavbarDropdown = ({children, count, showBadge, header, header_when_empty, footer, link, icon: Icon, id}) => (
    <Dropdown id={id} as={NavItem}>
        <Dropdown.Toggle className="nav-icon me-2" as={NavLink}>
            <div className="position-relative">
                <Icon className="align-middle" size={18}/>
                {showBadge ? <span className="indicator" style={{color: '#ffffff'}}>{count}</span> : null}
            </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-lg dropdown-menu-end py-0">
            {/*<div className="dropdown-menu-header position-relative">*/}
            {/*    {count} {header}*/}
            {/*</div>*/}
            <Dropdown.Item as={Link} to={link} className="dropdown-menu-header position-relative">
                {
                    count > 0 ? (
                        <Fragment>
                            {count} {header}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {header_when_empty}
                        </Fragment>
                        )
                }
            </Dropdown.Item>
            <ListGroup>{children}</ListGroup>
            {
                footer && (
                    <Dropdown.Item as={Link} to={link} className="dropdown-menu-footer">
                        <span className="text-muted">{footer}</span>
                    </Dropdown.Item>
                )
            }
        </Dropdown.Menu>
    </Dropdown>
);

NavbarDropdown.propTypes = {
    children: PropTypes.array,
    count: PropTypes.number,
    showBadge: PropTypes.bool,
    header: PropTypes.string,
    header_when_empty: PropTypes.string,
    footer: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.object,
    id: PropTypes.string
};

const NavbarDropdownItem = ({link, icon, title, description, time, spacing}) => (
    // the onClick function is an ugly workaround to fix the menu not closing after clicking an item
    // READ THIS: https://github.com/react-bootstrap/react-bootstrap/issues/541
    <ListGroupItem as={Link} to={link}  onClick={() => { document.body.click() }}>
        <Row className="align-items-center g-0">
            <Col xs={2}>{icon}</Col>
            <Col xs={10} className={spacing ? "ps-2" : null}>
                <div className="text-dark text-truncate" style={{maxWidth: '350px'}}>{title}</div>
                <div className="text-muted small mt-1 text-truncate" style={{maxWidth: '300px'}}>{description}</div>
                <div className="text-muted small mt-1"><TimeAgo date={time}/></div>
            </Col>
        </Row>
    </ListGroupItem>
);

NavbarDropdownItem.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    time: PropTypes.string,
    icon: PropTypes.object,
    spacing: PropTypes.bool
};

export {
    NavbarDropdown,
    NavbarDropdownItem,
}