import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import Auth0App from "./Auth0App";
import NotificationsToaster from "./components/NotificationsToaster";
import {ThemeProvider} from "./utils/contexts/ThemeContext";

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider>
            {/* the container for the notifications */}
            <NotificationsToaster/>

            <Auth0App/>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
