import React from "react";
import { useLocation } from "react-router-dom";

import reduceChildRoutes from "./reduceChildRoutes";
import PropTypes from "prop-types";

const SidebarNavList = (props) => {
  const { pages, depth, license} = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth, license }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

SidebarNavList.propTypes = {
  pages: PropTypes.array,
  depth: PropTypes.number,
  license: PropTypes.object
}

export default SidebarNavList;
