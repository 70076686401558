import React from "react";

const CallToAction = () => (
    // position the component to the bottom of the sidebar
    // <div style={{"position": "absolute", "display": "inline-block", "bottom": 0}}>
        <div className="sidebar-cta">
            <div className="sidebar-cta-content">
                <strong className="d-inline-block mb-2">Free Trial Expiring</strong>
                <div className="mb-3 text-sm">
                    Your free trial will expire in 2 days.
                    Subscribe now to enjoy the benefits of Aelium AutoML.
                </div>

                <div className="d-grid">
                    {/*<a className="btn btn-primary" target="_blank" rel="noreferrer">Subscribe</a>*/}
                </div>
            </div>
        </div>
    // </div>
);

export default CallToAction;