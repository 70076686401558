import React, {Fragment} from "react";
import {Badge, Button, Card, Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import Moment from "react-moment";
import {Bell} from "react-feather";
import {useLicense} from "../../utils/hooks/license";
import Error from "../../components/Error";
import {Link, useLocation} from "react-router-dom";
import moment from 'moment';
import {Tooltip} from "@material-ui/core";
import young from "../../assets/young_platform.svg"

const UserLicenseLevel = {
    FREE: 0,
    PARTNER: 1,
    TRADER: 2,
    PRO: 3,
    PREMIUM: 4
}

const Partners = {
    NONE: 0,
    YOUNG: 1,
}

function license_string(level) {
    switch (level) {
        case UserLicenseLevel.FREE:
            return 'FREE'
        case UserLicenseLevel.TRADER:
            return 'TRD'
        case UserLicenseLevel.PRO:
            return 'PRO'
        case UserLicenseLevel.PREMIUM:
            return 'PUM'
        default:
            return 'UNK'
    }
}

const LicenseExpiration = ({license}) => {

    if (!license.expiration_date)
        return <Fragment>Never</Fragment>

    if (license.expired)
        return <Badge bg="warning">{license.expiration_date} EXPIRED</Badge>

    if (license.auto_renew)
        return <span><Moment format="YYYY-MM-DD">{license.expiration_date}</Moment> (will auto renew)</span>

    return <Moment format="YYYY-MM-DD">{license.expiration_date}</Moment>
}

LicenseExpiration.propTypes = {
    license: PropTypes.object
}

function partner_string_to_code(partner) {
    switch (partner) {
        case 'Young':
            return Partners.YOUNG
        default:
            return Partners.NONE
    }
}

const PartnerLogo = ({partner}) => {

    switch (partner) {
        case Partners.YOUNG:
            return <img src={young} alt="young_platform" height='20px'/>
        default:
            return <Badge bg="secondary">UNKNOWN PARTNER</Badge>
    }
}

PartnerLogo.propTypes = {
    partner: PropTypes.number
}

const LicenseLevel = ({level, partner}) => {

    switch (level) {
        case UserLicenseLevel.FREE:
            return (
                <Badge bg="light" text="dark">FREE</Badge>
            )
        case UserLicenseLevel.PARTNER:
            if (partner)
                return (<PartnerLogo partner={partner}/>)
            else
                return (<Badge bg="info">PARTNER</Badge>)
        case UserLicenseLevel.TRADER:
            return (
                <Badge bg="aelium-light">TRADER</Badge>
            )
        case UserLicenseLevel.PRO:
            return (
                <Badge bg="aelium">PRO</Badge>
            )
        case UserLicenseLevel.PREMIUM:
            return (
                <Badge bg="aelium">PREMIUM</Badge>
            )
        default:
            return (
                <Badge bg="secondary">UNKNOWN</Badge>
            )
    }
}

LicenseLevel.propTypes = {
    level: PropTypes.number,
    partner: PropTypes.number,
}

const License = ({license}) => {

    return (
        <Card id="license">
            <Card.Header>
                <Card.Title tag="h5">License Plan</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={4} md={4} lg={3}>Active Plan:</Col>
                    <Col>
                        <h3>
                            <LicenseLevel level={license.level} partner={license.partner}/>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={4} lg={3}>Expiration:</Col>
                    <Col>
                        <LicenseExpiration license={license}/>
                    </Col>
                </Row>
                {
                    license.expired && (
                        <Row>
                            <Col xs={4} md={4} lg={3} className="text-danger">Account disabled in:</Col>
                            <Col>
                                <Badge bg="danger">{moment().to(moment(license.expiration_date).add(30, 'days'))}</Badge>
                            </Col>
                        </Row>
                    )
                }
            </Card.Body>
            <Card.Footer>
                {
                    license.level !== UserLicenseLevel.PREMIUM && (
                        <Button as={Link} to="/subscription" variant="outline-primary" className="float-end">Change Plan</Button>
                    )
                }
            </Card.Footer>
        </Card>
    )
}

License.propTypes = {
    license: PropTypes.object,
}

const LicenseBadge = ({level}) => {
    let name = ''
    let color = 'secondary'
    let lic = ''

    switch (level) {
        case UserLicenseLevel.FREE:
            name = 'FREE'
            color = 'aelium-light'
            lic = 'FREE'
            break
        case UserLicenseLevel.TRADER:
            name = 'TRADER'
            color = 'aelium-light'
            lic = 'TRD'
            break
        case UserLicenseLevel.PRO:
            name = 'PRO'
            color = 'aelium'
            lic = 'PRO'
            break
        case UserLicenseLevel.PREMIUM:
            name = 'PREMIUM'
            color = 'aelium'
            lic = 'PUM'
            break
        default:
    }

    return (
        <Tooltip title={`${name} license required`} placement="top" arrow>
            <Badge className="me-2" bg={color} size={18}>
                {lic}
            </Badge>
        </Tooltip>
    )
}

LicenseBadge.propTypes = {
    level: PropTypes.number
}


const LicenseWarning = () => {
    const {license, isLoading, error} = useLicense()
    const location = useLocation();

    if (isLoading) return <Fragment/>
    if (error) return <Error message={error}/>

    // don't display the message if we are renewing the license on the subscription page
    if (location.pathname === '/subscription') return <Fragment/>

    // not expiring, don't display anything
    if (!license.expiring) return <Fragment/>

    // display the message if expired
    return (
        <div id="license-warning" className="alert alert-warning alert-outline-coloured" role="alert">
            <div className="alert-icon">
                <Bell size={16}/>
            </div>
            <div className="alert-message" style={{lineHeight: '30px'}}>
                <strong>
                    Your license is about to expire on {license.expiration_date}, please consider renewing it...
                </strong>
                <div className="float-end">
                    <Button as={Link} size="sm" to="/subscription">Renew</Button>
                </div>
            </div>
        </div>
    )
}

const LicenseUpgradeSuggestion = ({what, value}) => {
    const {license, isLoading, error} = useLicense()

    if (isLoading) return <Fragment/>
    if (error) return <Error message={error}/>

    if ((typeof value === "boolean") && license[what] === true) {
        return <Fragment/>
    }

    if ((typeof value === "number") && (license[what] === null || license[what] > value)) {
        return <Fragment/>
    }

    let explanation = ''

    switch (what) {
        case 'files_quota':
            explanation = 'Your license does not include File Storage'
            break
        case 'trading':
            explanation = 'Your license does not include Trading Accounts'
            break
        case 'trading_dashboard':
            explanation = 'Your license does not include Performance Dashboard'
            break
        case 'strategies':
            explanation = 'You reached the Strategies quota in your license'
            break
        case 'portfolios':
            explanation = 'You reached the Portfolios quota in your license'
            break
        case 'pipelines':
            explanation = 'You reached the Pipelines quota in your license'
            break
    }
    return (
        <div id="license-warning" className="alert alert-warning alert-outline-coloured" role="alert">
            <div className="alert-icon">
                <Bell size={16}/>
            </div>
            <div className="alert-message" style={{lineHeight: '30px'}}>
                <strong>
                    {explanation}, please consider upgrading it...
                </strong>
                <div className="float-end">
                    <Button as={Link} size="sm" to="/subscription">Upgrade...</Button>
                </div>
            </div>
        </div>
    )
}

LicenseUpgradeSuggestion.propTypes = {
    what: PropTypes.string,
    value: PropTypes.any
}

export {
    UserLicenseLevel,
    License,
    LicenseWarning,
    LicenseLevel,
    LicenseExpiration,
    LicenseBadge,
    LicenseUpgradeSuggestion,
    PartnerLogo,
    partner_string_to_code,
    license_string
}