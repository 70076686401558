import React, {Fragment, useEffect} from "react";
import {Dropdown, NavItem, NavLink} from "react-bootstrap";
import {Compass, Folder, HelpCircle, Settings, Sliders, User as UserProfile, Users} from "react-feather";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import {useProfile} from "../../utils/hooks/profile";
import checkRole from "../../utils/roles/roles";
import {useLicense} from "../../utils/hooks/license";
import {LicenseBadge, UserLicenseLevel} from "../../views/profile/License";
import {useSettings} from "../../utils/hooks/settings";
import useTheme from "../../utils/hooks/theme";

const User = () => {
    const {user, logout} = useAuth0();
    const {profile, isLoading, error} = useProfile()
    const {settings} = useSettings()
    const { setTheme } = useTheme()
    const {license, isLoading: licenseLoading} = useLicense()

    useEffect(() => {
        // sync the theme from settings to the local cache
        if (settings?.display?.theme) {
            setTheme(settings.display.theme);
        }
    }, [settings]);

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    return (
        <Dropdown id="user" as={NavItem} align="end">
            <span className="d-inline-block d-sm-none">
                <Dropdown.Toggle as="a" className="nav-link">
                  <img className="avatar img-fluid rounded-circle" src={isLoading || error ? user.picture : profile.avatar} alt="avatar"/>
                </Dropdown.Toggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <Dropdown.Toggle id="toggle-user-menu" as={NavLink}>
                <img className="avatar img-fluid rounded-circle me-2"
                     src={isLoading || error ? user.picture : profile.avatar}
                     alt={isLoading || error ? user.name : profile.name}/>
                <span className="text-dark">{isLoading || error ? user.name : profile.name}</span>
              </Dropdown.Toggle>
            </span>
            <Dropdown.Menu>
                <Dropdown.Item id='profile' as={Link} to="/profile">
                    <UserProfile size={18} className="align-middle me-2"/>
                    Profile
                </Dropdown.Item>
                {
                    !isLoading && (
                        <Dropdown.Item id='settings' as={Link} to="/settings">
                            <Settings size={18} className="align-middle me-2"/>
                            Settings
                        </Dropdown.Item>
                    )
                }
                <Dropdown.Divider/>
                <Dropdown.Item id='data' as={Link} to="/trading/manage">
                    {
                        !licenseLoading && license && license.trading === 0 ? (
                            <LicenseBadge level={UserLicenseLevel.TRADER}/>
                        ) : (
                            <Sliders size={18} className="align-middle me-2"/>
                        )
                    }
                    Trading Accounts
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item id='data' as={Link} to="/data">
                    {
                        !licenseLoading && license && license.files_quota === 0 ? (
                            <LicenseBadge level={UserLicenseLevel.TRADER}/>
                        ) : (
                            <Folder size={18} className="align-middle me-2"/>
                        )
                    }
                    My Data
                </Dropdown.Item>
                <Dropdown.Divider/>
                {
                    !isLoading && (
                        <Dropdown.Item id='support' as={Link} to="/messages?support=true">
                            <HelpCircle size={18} className="align-middle me-2"/>
                            Support
                        </Dropdown.Item>
                    )
                }
                {
                    !isLoading && checkRole(profile, 'Aelium Staff') && (
                        <Fragment>
                            <Dropdown.Divider/>
                            <Dropdown.Item id='users' as={Link} to="/users">
                                <Users size={18} className="align-middle me-2"/>
                                Users
                            </Dropdown.Item>
                            <Dropdown.Item id='metrics' as={Link} to="/users/metrics">
                                <Compass size={18} className="align-middle me-2"/>
                                Metrics
                            </Dropdown.Item>
                        </Fragment>
                    )
                }
                <Dropdown.Divider/>
                <Dropdown.Item id='signout' onClick={() => logoutWithRedirect()}>Sign out</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default User;