import React from "react";
import {Button, Nav} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
    const {loginWithRedirect} = useAuth0();

    return (
        <Nav.Item>
            <Button id="login" color="primary" className="btn-margin" onClick={() => loginWithRedirect()}>
                Log in
            </Button>
        </Nav.Item>
    )
};

export default Login;