import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types'
import {Search} from 'semantic-ui-react'
import _ from 'lodash'
import {Col, Container, Row} from "react-bootstrap";
import {useHotkeys} from "react-hotkeys-hook";


const topics = {
    "app": {
        "name": "app",
        "results": [
            // Settings
            {id: 10000, "title": "settings", "description": "Settings", "path": "/settings"},
            {id: 10001, "title": "dark mode", "description": "Dark mode theme", "path": "/settings"},
            {id: 10001, "title": "notifications", "description": "Notifications settings", "path": "/settings"},
            {id: 10002, "title": "web push", "description": "Notifications settings", "path": "/settings"},
            // Home
            {id: 1000, "title": "home", "description": "Home", "path": "/"},
            {id: 1001, "title": "stats", "description": "Home", "path": "/"},
            // Footer
            {id: 1010, "title": "tos", "description": "Terms of Service", "path": "/tos"},
            {id: 1011, "title": "terms of service", "description": "Terms of Service", "path": "/tos"},
            {id: 1012, "title": "privacy", "description": "Privacy", "path": "/privacy"},
            // Profile
            {id: 1020, "title": "profile", "description": "Profile", "path": "/profile"},
            {id: 1022, "title": "nickname", "description": "Profile", "path": "/profile"},
            {id: 1023, "title": "quota", "description": "Profile", "path": "/profile"},
            {id: 1024, "title": "license", "description": "Profile", "path": "/profile"},
            // License
            {id: 1030, "title": "license plan", "description": "License Plan", "path": "/subscription"},
            // Notifications
            {id: 1040, "title": "notifications", "description": "Notifications", "path": "/notifications"},
            // Messages
            {id: 1050, "title": "messages", "description": "Messages", "path": "/messages"},
            // Dashboards
            {id: 1060, "title": "predictions dashboard", "description": "Dashboards > Predictions", "path": "/dashboards/predictions"},
            {id: 1061, "title": "signals dashboard", "description": "Dashboards > Signals", "path": "/dashboards/signals"},
            {id: 1062, "title": "live strategies dashboard", "description": "Dashboards > Live Strategies", "path": "/dashboards/strategies"},
            {id: 1063, "title": "live portfolios dashboard", "description": "Dashboards > Live Portfolios", "path": "/dashboards/portfolios"},
            {id: 1064, "title": "performance dashboard", "description": "Dashboards > Performance", "path": "/dashboards/performance"},
            // My Data
            {id: 1070, "title": "my data", "description": "My Data", "path": "/data"},
            {id: 1071, "title": "files", "description": "My Data", "path": "/data"},
            // Pipelines
            {id: 1080, "title": "pipelines", "description": "Pipelines > Manage", "path": "/pipelines/manage"},
            {id: 1081, "title": "new pipeline", "description": "Pipelines > New", "path": "/pipelines/new"},
            {id: 1082, "title": "advanced", "description": "Pipelines > New", "path": "/pipelines/new"},
            {id: 1084, "title": "manage pipelines", "description": "Pipelines > Manage", "path": "/pipelines/manage"},
            {id: 1085, "title": "meta-labeling", "description": "Pipelines > New", "path": "/pipelines/templates"},
            {id: 1086, "title": "signals", "description": "Pipelines > New", "path": "/pipelines/templates"},
            // Strategies
            {id: 1090, "title": "strategies", "description": "Strategies > Manage", "path": "/strategies/manage"},
            {id: 1091, "title": "new strategy", "description": "Strategies > New", "path": "/strategies/new"},
            {id: 1092, "title": "manage strategies", "description": "Strategies > Manage", "path": "/strategies/manage"},
            // Portfolios
            {id: 1100, "title": "portfolios", "description": "Portfolios > Manage", "path": "/portfolios/manage"},
            {id: 1101, "title": "new portfolio", "description": "Portfolios > New", "path": "/portfolios/new"},
            {id: 1102, "title": "manage portfolios", "description": "Portfolios > Manage", "path": "/portfolios/manage"},
            // Trading Accounts
            {id: 1200, "title": "trading accounts", "description": "Trading Accounts > Manage", "path": "/trading/manage"},
            // Alpha Search
            {id: 1300, "title": "alpha search strategies", "description": "Alpha Search > Strategies", "path": "/alpha/strategies"},
            {id: 1301, "title": "alpha search portfolios", "description": "Alpha Search > Portfolios", "path": "/alpha/portfolios"},
        ]
    },
    "docs": {
        "name": "documentation",
        "results": [
            // Introduction
            {id: 0, "title": "documentation", "description": "Docs > Introduction", "path": "/docs/introduction"},
            {id: 1, "title": "introduction", "description": "Docs > Introduction", "path": "/docs/introduction"},
            // Getting Started
            {id: 10, "title": "getting started", "description": "Tutorials > Strategy Creation", "path": "tutorials/quickstart-strategy"},
            {id: 11, "title": "getting started", "description": "Tutorials > Portfolio Creation", "path": "tutorials/quickstart-portfolio"},
            // Alpha Search
            {id: 100, "title": "alpha search", "description": "Docs > Alpha Search", "path": "/docs/alpha"},
            // Strategies
            {id: 200, "title": "strategy", "description": "Docs > Strategies", "path": "/docs/strategies"},
            {id: 201, "title": "pipelines", "description": "Docs > Strategies", "path": "/docs/strategies"},
            {id: 202, "title": "predict", "description": "Docs > Strategies", "path": "/docs/strategies"},
            {id: 203, "title": "live", "description": "Docs > Strategies", "path": "/docs/strategies"},
            {id: 204, "title": "automation", "description": "Docs > Strategies", "path": "/docs/strategies"},
            {id: 205, "title": "backtest", "description": "Docs > Strategies", "path": "/docs/strategies"},
            {id: 206, "title": "aeliumization", "description": "Docs > Strategies", "path": "/docs/strategies"},
            // Portfolios
            {id: 300, "title": "portfolio", "description": "Docs > Portfolios", "path": "/docs/portfolios"},
            {id: 301, "title": "pipelines", "description": "Docs > Portfolios", "path": "/docs/portfolios"},
            {id: 302, "title": "rebalance", "description": "Docs > Portfolios", "path": "/docs/portfolios"},
            {id: 303, "title": "live", "description": "Docs > Portfolios", "path": "/docs/portfolios"},
            {id: 304, "title": "automation", "description": "Docs > Portfolios", "path": "/docs/portfolios"},
            {id: 305, "title": "backtest", "description": "Docs > Portfolios", "path": "/docs/portfolios"},
            {id: 306, "title": "aeliumization", "description": "Docs > Portfolios", "path": "/docs/portfolios"},
            // Trading Accounts
            {id: 400, "title": "trading", "description": "Docs > Trading Accounts", "path": "/docs/trading"},
            {id: 401, "title": "execution", "description": "Docs > Trading Accounts", "path": "/docs/trading"},
            {id: 450, "title": "API keys", "description": "Docs > Trading Accounts", "path": "/docs/trading/exchanges"},
            {id: 451, "title": "exchange", "description": "Docs > Trading Accounts", "path": "/docs/trading/exchanges"},
            // Video Tutorials
            {id: 500, "title": "tutorial", "description": "Tutorials > Strategy Creation", "path": "tutorials/quickstart-strategy"},
            {id: 500, "title": "tutorial", "description": "Tutorials > Portfolio Creation", "path": "tutorials/quickstart-portfolio"},
        ]
    }
}

const categoryLayoutRenderer = ({categoryContent, resultsContent}) => (
    <Container className="g-0">
        <Row>
            <Col xs={2} >
                <div className="mt-1">{categoryContent}</div>
            </Col>
            <Col xs={10} >
                {resultsContent}
            </Col>
        </Row>
    </Container>
)

categoryLayoutRenderer.propTypes = {
    categoryContent: PropTypes.node,
    resultsContent: PropTypes.node,
}

const categoryRenderer = ({name}) => <span>{name}</span>

categoryRenderer.propTypes = {
    name: PropTypes.string,
}

const resultRenderer = ({title, description}) => {
    return (
        <Row>
            <Col xs={5}>
                {title}
            </Col>
            <Col xs={7}>
                <span className="text-primary">{description}</span>
            </Col>
        </Row>
    )
}

resultRenderer.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}


const SearchTopic = () => {
    const [results, setResults] = useState([]);
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const fieldRef = useRef();
    useHotkeys('ctrl+/, ctrl+f, ctrl+s, meta+s', () => {
        fieldRef.current && fieldRef.current.focus()
    }, {filterPreventDefault: true, filter: () => true});

    function onBlur() {
        // don't remove the query on mobile, otherwise when you loose the focus because of the mobile keyboard,
        // you will not be able to use search
        // if (isMobile)
        //     return

        setValue('')
    }

    function handleResultSelect(e, {result}) {
        // reset the search field
        setValue('')
        // go to the selected page
        navigate(result.path)
    }

    function handleSearchChange(e, {value}) {
        setValue(value)

        setTimeout(() => {
            if (value.length < 1) {
                setValue('')
                setResults([])
                return
            }

            const re = new RegExp(`\\b${value}`, 'i')
            const isMatch = (result) => re.test(result.title)

            const filteredResults = _.reduce(
                topics,
                (memo, data, name) => {
                    const results = _.filter(data.results, isMatch)
                    if (results.length) memo[name] = {name, results} // eslint-disable-line no-param-reassign

                    return memo
                },
                {},
            )

            setResults(filteredResults);
        }, 250)
    }

    return (
        <div className="d-none d-sm-inline-block">
            <Search
                input={{ref: fieldRef}}
                placeholder={"Search a topic..."}
                category
                onResultSelect={handleResultSelect}
                onSearchChange={_.debounce(handleSearchChange, 300, {leading: true,})}
                selectFirstResult={true}
                results={results}
                value={value}
                showNoResults={false}
                categoryLayoutRenderer={categoryLayoutRenderer}
                categoryRenderer={categoryRenderer}
                resultRenderer={resultRenderer}
                size={"small"}
                onBlur={onBlur}
            />
        </div>
    )
}

export default SearchTopic;