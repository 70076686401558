import React, {useState} from "react";
import {Button, Card, Form, Stack} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";

import aelium_logo from "../assets/aelium_logo_pos.png";


function SignUpCard() {
    const {loginWithRedirect} = useAuth0();
    const [agree, setAgree] = useState(false)

    return <Card className="text-center">
        <Card.Header>
            <img src={aelium_logo} className="mb-5 mt-4" alt="Aelium logo" width="106"/>
            <Card.Title>
                <h2 className="h2">Sign up to Aelium Platform</h2>
            </Card.Title>
        </Card.Header>
        <Card.Body className="text-center px-5">
            <p className="text-justify">
                By proceeding to create your account and use Aelium, you are agreeing to
                our <Link to="/tos">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>
            </p>
            <div className="text-start">
                <Form.Check type="checkbox" name="no-store" label="I explicitly agree" onChange={() => setAgree(!agree)}/>
            </div>

            <Stack className="mb-4 mt-2" style={{height: "50px"}}>
                <Button id="signup" variant="primary" style={{height: "50px"}} disabled={!agree}
                        onClick={() => loginWithRedirect({authorizationParams: {screen_hint: "signup",}})}>
                    Continue
                </Button>
            </Stack>

        </Card.Body>
    </Card>;
}

const SignUp = () => {

    return (
        <div className="align-content-center vh-100" style={{backgroundColor: '#000000'}}>
            <div style={{height: '80px'}}/>
            <div className='text-center mx-auto' style={{width: '25rem'}}>
                <SignUpCard/>
            </div>
        </div>
    )
}

export default SignUp;
