
function setAppBadgeUnreadCount(x) {
    // Try to set the app badge.
    try {
        navigator.setAppBadge(x);
    } catch (e) {
        // The badge is not supported, or the user has prevented the app from setting a badge.
        //console.log(e)
    }
}

function clearAppBadgeUnreadCount() {
    try {
        navigator. clearAppBadge();
    } catch (e) {
        // The badge is not supported, or the user has prevented the app from setting a badge.
    }
}

export {
    setAppBadgeUnreadCount,
    clearAppBadgeUnreadCount
}