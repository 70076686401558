import React from "react";
import PropTypes from 'prop-types';

import "../assets/css/gears.css"

const Auth0Error = ({message}) => (
    <div className="container">
        <div className="row p-3"/>
        <div className="row">
            <div className="col-md-6 text-center">
                <div className="p-3">
                    <h1 className="text-primary" style={{'fontSize': 50}}>Jammed Gears</h1>
                </div>
                <div className="gears">
                    <div className="gear one">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                    <div className="gear two">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                    <div className="gear three">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="p-5">
                    <h2 className="text-primary">UH OH! Something is broken...</h2>
                    <p>ERROR:<br/>{message}</p>
                    <a className="btn btn-primary" href="/" role="button">Take Me Home</a>
                </div>
            </div>
        </div>
    </div>
);

Auth0Error.propTypes = {
    message: PropTypes.string
};

export default Auth0Error;
