import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import SidebarNav from "./SidebarNav";
import PropTypes from "prop-types";
import CallToAction from "./CallToAction";
import Logo from "./Logo";

import items from "./SidebarItems"

const Sidebar = ({ isOpen, showFooter = false }) => {

  return (
    <nav id="sidebar" className={`sidebar${!isOpen ? " collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>

          <Logo/>

          <SidebarNav items={items} />
          {!!showFooter && <CallToAction />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  showFooter: PropTypes.bool
}

export default Sidebar;
