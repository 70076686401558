import {
    useDeleteWithToken,
    useGetDataWithToken,
    useGetWithToken,
    usePostWithToken,
    usePutWithToken,
    useRawDeleteWithToken,
    useRawPutWithToken
} from "../network/network";
import { useSWRConfig } from 'swr'

function useUnreadMessagesAndCount() {
    const { data: count, isLoading: countLoading, error: countError } = useGetDataWithToken('/messages/count/?unread=true')
    const { data: messages, isLoading: messagesLoading, error: messagesError } = useGetDataWithToken(() => count.count > 0 ? '/messages/?unread=true&limit=5' : null)

    return {
        count: count ? count.count : count,
        messages : messages,
        isLoading: countLoading || (!countError && count.count > 0 && messagesLoading),
        error: countError || messagesError
    }
}

function useUnreadMessagesCount() {
    const { data, isLoading, error } = useGetDataWithToken('/messages/count/?unread=true')

    return {
        count: data ? data.count : data,
        isLoading: isLoading,
        error: error
    }
}

function useUnreadMessages() {
    const { data, isLoading, error } = useGetDataWithToken('/messages/?unread=true&limit=5')

    return {
        messages: data,
        isLoading: isLoading,
        error: error
    }
}

function useMessagesCount() {
    const { data, isLoading, error } = useGetDataWithToken('/messages/count')

    return {
        count: data ? data.count : data,
        isLoading: isLoading,
        error: error
    }
}


function useMessages() {
    const { data, isLoading, error } = useGetDataWithToken('/messages/')

    return {
        messages: data,
        isLoading: isLoading,
        error: error
    }
}

function useMessage(id) {
    const { data, isLoading, error } = useGetDataWithToken(`/messages/${id}/`)
    const { mutate } = useSWRConfig()

    return {
        message: data,
        isLoading: isLoading,
        error: error,
        invalidateCache: function () {
            // invalidate the unread count
            mutate('/messages/count/?unread=true')
        }
    }
}

function useMessageSaveAttachment(id) {
    const { getWithToken } = useGetWithToken(`/messages/${id}/save-attachment/`)

    return {
        saveAttachment: getWithToken,
    }
}

function useToggleUnreadMessage(id) {
    const { putWithToken } = usePutWithToken(`/messages/${id}/`)
    const { mutate } = useSWRConfig()

    return {
        markAsUnreadMessage: putWithToken,
        invalidateCache: function () {
            // force the cache revalidate on the message list and counter
            mutate('/messages/count/?unread=true')
            mutate('/messages/')
        }
    }
}

function useToggleUnreadRawMessage() {
    const { putRawWithToken } = useRawPutWithToken()
    const { mutate } = useSWRConfig()

    return {
        toggleUnreadMessage: putRawWithToken,
        invalidateCache: function () {
            // force the cache revalidate on the message list and counter
            mutate('/messages/count/?unread=true')
            mutate('/messages/')
        }
    }
}

function useDeleteMessage(id) {
    const { deleteWithToken } = useDeleteWithToken(`/messages/${id}/`)
    const { mutate } = useSWRConfig()

    return {
        deleteMessage: deleteWithToken,
        invalidateCache: function () {
            mutate('/messages/')
        }
    }
}

function useDeleteRawMessage() {
    const { deleteRawWithToken } = useRawDeleteWithToken()
    const { mutate } = useSWRConfig()

    return {
        deleteMessageRaw: deleteRawWithToken,
        invalidateCache: function () {
            mutate('/messages/')
        }
    }
}
function useSendMessage() {
    const { postWithToken } = usePostWithToken(`/messages/`)

    return {
        sendMessage: postWithToken
    }
}

export {
    useUnreadMessagesAndCount,
    useUnreadMessagesCount,
    useUnreadMessages,
    useMessagesCount,
    useMessages,
    useMessage,
    useMessageSaveAttachment,
    useToggleUnreadMessage,
    useToggleUnreadRawMessage,
    useDeleteMessage,
    useDeleteRawMessage,
    useSendMessage,
};
