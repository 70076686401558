import {useAuth0} from "@auth0/auth0-react";
import axios from 'axios';
import useSWR, {useSWRConfig} from "swr";
import useSWRImmutable from 'swr/immutable'

import apiUrl from "./api_url.json";

// this is the default url for our Django Frontend
axios.defaults.baseURL = apiUrl.api_url

const handle_error = function (error) {
    if (error.response) {
        // could not connect to server (special status code 0)
        if (error.response.status === 0)
            throw {error: "Could not connect to server"}

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(`Response Error [${error.response.status}] ${JSON.stringify(error.response.data)}`);
        throw error.response.data;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error('Request Error', error.request);
        throw {error: "Could not connect to server"}
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Generic Error', error.message);
        throw error.message;
    }
}

function axios_error(error) {
    console.error(`Axios Error: ${JSON.stringify(error)}`)
    // parse the error message
    if (error.error_description)
        return error.error_description
    if (error.detail)
        return error.detail
    if (error.error)
        return error.error

    return JSON.stringify(error)
}

function useGetDataWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();

    const {data, error, isValidating} = useSWR(url,
        async (url) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return await axios.get(url, {
                    headers:  {
                        authorization: `Bearer ${accessToken}`,
                    },
                }).then(function (response) {
                    return response.data
                }).catch(handle_error)
            } catch (error) {
                throw axios_error(error)
            }
        }
    );

    return {
        data: data,
        isLoading: !error && !data,
        error: error,
        isValidating: isValidating
    }
}

function useGetDataWithOrWithoutToken(url) {
    const {getAccessTokenSilently, isAuthenticated} = useAuth0();

    const {data, error, isValidating} = useSWR(url,
        async (url) => {
            try {
                const accessToken = isAuthenticated ? await getAccessTokenSilently() : null;
                return await axios.get(url, {
                    headers: isAuthenticated ? {
                        authorization: `Bearer ${accessToken}`,
                    } : {},
                }).then(function (response) {
                    return response.data
                }).catch(handle_error)
            } catch (error) {
                throw axios_error(error)
            }
        }
    );

    return {
        data: data,
        isLoading: !error && !data,
        error: error,
        isValidating: isValidating
    }
}

function useImmutableGetDataWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();

    const {data, error} = useSWRImmutable(url,
        async (url) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return await axios.get(url, {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }).then(function (response) {
                    return response.data
                }).catch(handle_error)
            } catch (error) {
                throw axios_error(error)
            }
        }
    );

    return {
        data: data,
        isLoading: !error && !data,
        error: error
    }
}

function useImmutableGetDataWithOrWithoutToken(url) {
    const {getAccessTokenSilently, isAuthenticated} = useAuth0();

    const {data, error} = useSWRImmutable(url,
        async (url) => {
            try {
                const accessToken = isAuthenticated ? await getAccessTokenSilently() : null;
                return await axios.get(url, {
                    headers: isAuthenticated ? {
                        authorization: `Bearer ${accessToken}`,
                    } : {},
                }).then(function (response) {
                    return response.data
                }).catch(handle_error)
            } catch (error) {
                throw axios_error(error)
            }
        }
    );

    return {
        data: data,
        isLoading: !error && !data,
        error: error
    }
}

function useImmutableGetData(url) {
    const {data, error} = useSWRImmutable(url,
        async (url) => {
            try {
                return await axios.get(url).then(function (response) {
                    return response.data
                }).catch(handle_error)
            } catch (error) {
                throw axios_error(error)
            }
        }
    );

    return {
        data: data,
        isLoading: !error && !data,
        error: error
    }
}

function useGetWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();

    return {
        getWithToken: async (id=null) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return {
                    // if we provided an additional id, add to the end of the url
                    data: await axios.get(url + `${id ? id : ''}`, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        }
                    }).then(function (response) {
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function useGetBinaryWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();

    return {
        getWithToken: async (id=null) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return {
                    // if we provided an additional id, add to the end of the url
                    data: await axios.get(url + `${id ? id : ''}`, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                        responseType: 'arraybuffer'
                    }).then(function (response) {
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function usePostWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();
    const { mutate } = useSWRConfig()

    return {
        postWithToken: async (params) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return {
                    data: await axios.post(url, params, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                    }).then(function (response) {
                        // broadcast to SWR that the cache is invalidated
                        mutate(url)
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function usePost(url) {
    return {
        post: async (params) => {
            try {
                return {
                    data: await axios.post(url, params).then(function (response) {
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function usePutWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();
    const { mutate } = useSWRConfig()

    return {
        putWithToken: async (params) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return {
                    data: await axios.put(url, params, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                    }).then(function (response) {
                        // broadcast to SWR that the cache is invalidated and we have a new value to store
                        mutate(url, response.data, false)
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function useRawPutWithToken() {
    const {getAccessTokenSilently} = useAuth0();

    return {
        putRawWithToken: async (url, params) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return {
                    data: await axios.put(url, params, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                    }).then(function (response) {
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function useDeleteWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();
    const { mutate } = useSWRConfig()

    return {
        deleteWithToken: async (params) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return {
                    data: await axios.delete(url, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                        data : params
                    }).then(function (response) {
                        // broadcast to SWR that the cache is invalidated and the list has changed
                        mutate(url)
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function useRawDeleteWithToken() {
    const {getAccessTokenSilently} = useAuth0();

    return {
        deleteRawWithToken: async (url) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return {
                    data: await axios.delete(url, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                    }).then(function (response) {
                        return response.data
                    }).catch(handle_error),
                    error: null
                }
            } catch (error) {
                return {
                    data: null,
                    error: axios_error(error)
                }
            }
        }
    }
}

function useCheckWithToken(url) {
    const {getAccessTokenSilently} = useAuth0();

    return {
        checkWithToken: async (params) => {
            try {
                const accessToken = await getAccessTokenSilently();
                return await axios.get(url + params, {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }).then(function (response) {
                    return response.data
                }).catch(handle_error)
            } catch (error) {
                throw axios_error(error)
            }
        }
    }
}

export {
    useGetDataWithToken,
    useGetDataWithOrWithoutToken,
    useImmutableGetData,
    useImmutableGetDataWithToken,
    useImmutableGetDataWithOrWithoutToken,
    useGetWithToken,
    useGetBinaryWithToken,
    usePost,
    usePostWithToken,
    usePutWithToken,
    useRawPutWithToken,
    useDeleteWithToken,
    useRawDeleteWithToken,
    useCheckWithToken
};
