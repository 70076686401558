import React from "react";
import PropTypes from 'prop-types';
import {Card, Col, Container, Row} from "react-bootstrap";

import aelium_logo from "../assets/aelium_logo_pos.svg";

const Auth0Approval = ({message}) => (
    <div className="main d-flex w-100 justify-content-center">
        <Container className="d-flex flex-column">
            <Row className="h-100">
                <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                    <div className="d-table-cell align-middle">
                        <div className="text-center mt-4">
                            <Row className="g-0">
                                <Col className="text-end mt-3">
                                    <h2>Welcome to</h2>
                                </Col>
                                <Col className="text-start ms-1">
                                    <img src={aelium_logo} alt="Aelium logo" width="120"/>
                                </Col>
                            </Row>
                            <p className="lead">Your account was created successfully</p>
                        </div>
                        <Card>
                            <Card.Body>
                                <div className="m-sm-4">
                                    <div className="text-center">
                                        <h5>{message}</h5>
                                    </div>
                                </div>
                            </Card.Body>
                            <Card.Footer className="text-center">
                                {
                                    message.startsWith("Your account needs to be approved") && (
                                        <h5>The Aelium staff is going to review your account for approval.<br/> Please check your inbox for the confirmation email before retrying.</h5>
                                    )
                                }
                                <a className="btn btn-primary" href="/" role="button">Go Back</a>
                            </Card.Footer>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

Auth0Approval.propTypes = {
    message: PropTypes.string
};

export default Auth0Approval;
