/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef} from "react";
import {NavLink} from "react-router-dom";

import {Badge, Collapse} from "react-bootstrap";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const CustomRouterLink = forwardRef((props, ref) => (
    <React.Fragment ref={ref}>
        <NavLink {...props} />
    </React.Fragment>
));

const SidebarNavListItem = (props) => {
    const {
        title,
        href,
        depth = 0,
        children,
        icon: Icon,
        badge,
        open: openProp = false,
        currentRoute,
    } = props;

    const [open, setOpen] = React.useState(openProp);

    const handleToggle = () => {
        setOpen((state) => !state);
    };

    const isActive = () => {
        if (href === '/') {
            return currentRoute === href
        } else {
            return currentRoute.indexOf(href) === 0
        }
    }

    if (children) {

        if (!open && currentRoute.indexOf(href) === 0) {
            setOpen(true)
        }

        return (
            <li className={`sidebar-item ${open ? "active" : ""}`}>
                <a
                    className={`sidebar-link ${open ? "" : "collapsed"}`}
                    data-bs-toggle="collapse"
                    aria-expanded={open ? "true" : "false"}
                    depth={depth}
                    onClick={handleToggle}
                >
                    {Icon && <Icon className="feather align-middle"/>}{" "}
                    <span className="align-middle" depth={depth}>{title}</span>
                    {badge && (
                        <Badge className="badge-sidebar-primary" bg="" size={18}>
                            {badge}
                        </Badge>
                    )}
                    {open ? <div/> : <div/>}
                </a>
                <Collapse in={open}>
                    <ul className="sidebar-dropdown list-unstyled">{children}</ul>
                </Collapse>
            </li>
        );
    }

    return (
        <li className={`sidebar-item ${isActive() ? 'active': ''} ${Icon ? '' : 'ms-3'}`}>
            <CustomRouterLink
                depth={depth}
                to={href}
                activeclassname="active"
                className="sidebar-link"
            >
                {Icon && <Icon className="feather align-middle"/>}{" "}
                <span className="align-middle" depth={depth}>{title}</span>
                {badge && (
                    <Badge className="badge-sidebar-primary" bg="" size={18}>
                        {badge}
                    </Badge>
                )}
            </CustomRouterLink>
        </li>
    );
};

SidebarNavListItem.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    depth: PropTypes.number,
    children: PropTypes.object,
    icon: PropTypes.object,
    badge: PropTypes.any,
    open: PropTypes.bool,
    currentRoute: PropTypes.string
}

export default SidebarNavListItem;
