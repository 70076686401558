import React, {useEffect} from "react";
import {toast} from "react-hot-toast";
import {NavbarDropdown, NavbarDropdownItem} from "./NavbarDropdown";
import {useUnreadNotificationsAndCount} from "../../utils/hooks/notifications";

import {AlertTriangle, Award, Bell, Info} from "react-feather";
import {useNavigate} from "react-router-dom";
import {Toast} from "react-bootstrap";
import TimeAgo from "react-timeago";
import {setAppBadgeUnreadCount} from "../../views/notifications/badging/badging-api";


function notificationIconAndText(item, size= 18) {
    let icon = <Info size={size} className="text-primary"/>
    let icon_text = <span className="text-primary">Info</span>

    if (item.level === 3) {
        icon = <AlertTriangle size={size} className="text-danger"/>;
        icon_text = <span className="text-danger">Error</span>
    }

    if (item.level === 2) {
        icon = <Bell size={size} className="text-warning"/>;
        icon_text = <span className="text-warning">Warning</span>
    }

    if (item.level === 1) {
        icon = <Award size={size} className="text-success"/>;
        icon_text = <span className="text-success">Success</span>
    }
    return {icon, icon_text};
}

const Notifications = () => {
    const {count, notifications, isLoading, error} = useUnreadNotificationsAndCount()
    const navigate = useNavigate()

    useEffect(() => {
        /* process the notifications in reverse order, since the data is ordered by date descending */
        notifications && Array.from(notifications).reverse().map((item) => {
            let {icon, icon_text} = notificationIconAndText(item);
            /*
                we store the last notification_id in the localstorage, were we are exploiting
                the fact that the id is autoincrement in the database
             */
            if (localStorage.getItem('last_displayed_notification_id') < item.id) {
                toast.custom(
                    (t) => (
                        <Toast onClose={() => toast.dismiss(t.id)} show={t.visible}>
                            <Toast.Header>
                                <div className="me-2">{icon}</div>
                                <strong className="me-auto">{icon_text}</strong>
                                <small><TimeAgo date={item.timestamp}/></small>
                            </Toast.Header>
                            <Toast.Body >
                                <div style={{cursor: "pointer"}} onClick={() => {toast.dismiss(t.id); navigate(`/notifications?id=${item.id}`) } }>
                                    {item.subject}
                                </div>
                            </Toast.Body>
                        </Toast>
                    ),
                    {id: item.id, duration: 2000}
                )
                localStorage.setItem('last_displayed_notification_id', item.id)
            }

            return null
        })
    }, [notifications, navigate])

    if (isLoading) return <NavbarDropdown
        id="notifications"
        header="Loading Notifications"
        link="/notifications"
        icon={Bell}/>

    if (error) return <NavbarDropdown
        id="notifications"
        header={"Error Loading Notifications: " + error}
        link="/notifications"
        icon={Bell}/>

    // badging for Web App
    if (count !== null)
        setAppBadgeUnreadCount(count)

    return (
        <NavbarDropdown
            id="notifications"
            header="New Notifications"
            header_when_empty="Show Notifications"
            link="/notifications"
            icon={Bell}
            count={count}
            showBadge={count > 0}
        >

            {/* entries in the drop down menu should be ordered by date descending (same as the received data) */
                notifications && notifications.map((item, key) => {
                    let {icon} = notificationIconAndText(item);

                    return (
                        <NavbarDropdownItem
                            key={key}
                            icon={icon}
                            title={item.subject}
                            time={item.timestamp}
                            link={"/notifications?id=" + item.id}
                        />
                    );
                })}
        </NavbarDropdown>
    )
};

export default Notifications;
export {notificationIconAndText};
