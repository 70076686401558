import React from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import App from "./App";
import {getAuth0Config} from "./auth0_config";

function Auth0App() {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
    };

    // Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
    // for a full list of the available properties on the provider
    const config = getAuth0Config();

    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            authorizationParams={{
                ...(config.audience ? {audience: config.audience} : null),
                redirect_uri: window.location.origin,
                scope: "openid profile email offline_access"
            }}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            cacheLocation={"localstorage"}
            onRedirectCallback={onRedirectCallback}
        >
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </Auth0Provider>
    )
}

export default Auth0App;