import {useGetDataWithToken, usePutWithToken} from "../network/network";
import {useSWRConfig} from "swr";

function useSettings() {
    const { data, isLoading, error } = useGetDataWithToken(`/user/settings/`)

    return {
        settings: data && data.settings,
        isLoading: isLoading,
        error: error,
    }
}

function useUpdateSettings() {
    const { putWithToken } = usePutWithToken(`/user/settings/`)
    const { mutate } = useSWRConfig()

    return {
        saveSettings: putWithToken,
        invalidateCache: function () {
            mutate('/user/settings/')
        }
    }
}

export {
    useSettings,
    useUpdateSettings
};
