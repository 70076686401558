import {BookOpen, Layers, Sliders, Video, Home, PieChart, BarChart2, AtSign} from "react-feather";
import {UserLicenseLevel} from "../../views/profile/License";

const homeItems = [
    {
        href: "/",
        icon: Home,
        title: "Home",
    }
]

const dashboardsItems = [
    {
        href: "/dashboards",
        title: "Dashboards",
        icon: Sliders,
        children: [
            {
                href: "/dashboards/performance",
                title: "Performance",
                level: UserLicenseLevel.PRO,
            },
            {
                href: "/dashboards/assets",
                title: "Assets Returns",
            },
            {
                href: "/dashboards/strategies",
                title: "Live Strategies",
            },
            {
                href: "/dashboards/portfolios",
                title: "Live Portfolios",
            },
            {
                href: "/dashboards/predictions",
                title: "Predictions",
            },
            {
                href: "/dashboards/signals",
                title: "Signals",
            },
        ]
    }
]

const alphaSearchItems = [
    {
        href: "/alpha",
        title: "Alpha Search",
        icon: AtSign,
        children: [
            {
                href: "/alpha/trends",
                title: "Trends",
            },
            {
                href: "/alpha/strategies",
                title: "Strategies",
            },
            {
                href: "/alpha/portfolios",
                title: "Portfolios",
            }
        ]
    }
]

const strategiesItems = [
    {
        href: "/strategies",
        title: "Strategies",
        icon: BarChart2,
        children: [
            {
                href: "/strategies/new",
                title: "New",
            },
            {
                href: "/strategies/manage",
                title: "Manage",
            }
        ]
    }
]

const portfoliosItems = [
    {
        href: "/portfolios",
        title: "Portfolios",
        icon: PieChart,
        children: [
            {
                href: "/portfolios/new",
                title: "New",
            },
            {
                href: "/portfolios/manage",
                title: "Manage",
            }
        ]
    }
]

const pipelinesItems = [
    {
        href: "/pipelines",
        title: "Pipelines",
        icon: Layers,
        children: [
            {
                href: "/pipelines/new",
                title: "New",
            },
            {
                href: "/pipelines/manage",
                title: "Manage",
            }
            // {
            //     href: "/pipelines/templates",
            //     title: "Templates",
            // },
        ]
    }
]

const documentationItems = [
    {
        href: "/docs",
        title: "Documentation",
        icon: BookOpen,
        children: [
            {
                href: "/docs/introduction",
                title: "Introduction",
            },
            {
                href: "/docs/alpha",
                title: "Alpha Search",
            },
            {
                href: "/docs/strategies",
                title: "Strategies",
            },
            {
                href: "/docs/portfolios",
                title: "Portfolios",
            },
            {
                href: "/docs/trading",
                title: "Trading Accounts",
            }
        ]
    }
]

const tutorialsItems = [
    {
        href: "/tutorials",
        title: "Video Tutorials",
        icon: Video,
        children: [
            {
                href: "/tutorials/quickstart-strategy",
                title: "Strategy Creation",
            },
            {
                href: "/tutorials/quickstart-portfolio",
                title: "Portfolio Creation",
            },
            {
                href: "/tutorials/quickstart-trading-accounts",
                title: "Trading Accounts",
            },
        ]
    }
]

const navItems = [
    {
        pages: homeItems,
    },
    {
        pages: dashboardsItems,
    },
    {
        pages: alphaSearchItems,
    },
    {
        //title: "Strategies",
        pages: strategiesItems,
    },
    {
        pages: portfoliosItems,
    },
    {
        //title: "Pipelines",
        pages: pipelinesItems,
    },
    {
        //title: "Documentation",
        pages: documentationItems,
    },
    {
        pages: tutorialsItems,
    },
];

export default navItems;