import React, {Fragment} from "react";

import SidebarNavSection from "./SidebarNavSection";
import PropTypes from "prop-types";
import {useLicense} from "../../utils/hooks/license";
import {UserLicenseLevel} from "../../views/profile/License";

const SidebarNav = ({items}) => {
    let {license, isLoading, error} = useLicense()

    if (isLoading) return <Fragment/>
    // in case of error, fallback to the lowest level license
    if (error)
        license = {level: UserLicenseLevel.FREE}

    return (
        <ul className="sidebar-nav">
            {items && items.map((item, index) => {
                return (
                    <Fragment key={index}>
                        {
                            item.level === undefined || license.level >= item.level ? (
                                <SidebarNavSection key={index} pages={item.pages} title={item.title} license={license}/>
                            ) : (
                                <Fragment key={index}/>
                            )
                        }
                    </Fragment>
                )
            })}
        </ul>
    );
};

SidebarNav.propTypes = {
    items: PropTypes.array,
}

export default SidebarNav;
