import React, { lazy } from "react";

// Core
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import NotFound from "./components/NotFound";
import {ProtectedRoute} from "./ProtectedRoute";

// Navbar
const Subscription = lazy(() => import(/* webpackChunkName: "Subscription" */ "./views/subscription/Subscription"));
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ "./views/profile/Profile"));
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ "./views/settings/Settings"));
const Messages = lazy(() => import(/* webpackChunkName: "Messages" */ "./views/messages/Messages"));
const Notifications = lazy(() => import(/* webpackChunkName: "Notifications" */ "./views/notifications/Notifications"));
const UserData = lazy(() => import(/* webpackChunkName: "UserData" */ "./views/data/UserData"));
const TradingAccounts = lazy(() => import(/* webpackChunkName: "TradingAccounts" */ "./views/trading/TradingAccounts"));
const TradingAccountDetails = lazy(() => import(/* webpackChunkName: "TradingAccountDetails" */ "./views/trading/AccountDetails"));
const Users = lazy(() => import(/* webpackChunkName: "Users" */ "./views/users/Users"));
const Metrics = lazy(() => import(/* webpackChunkName: "Metrics" */ "./views/users/Metrics"));

// Footer
const Privacy = lazy(() => import(/* webpackChunkName: "Privacy" */ "./components/footer/Privacy"));
const TOS = lazy(() => import(/* webpackChunkName: "TOS" */ "./components/footer/TOS"));

// Home
const Home = lazy(() => import(/* webpackChunkName: "Home" */ "./views/home/Home"));

// Dashboards
const DashboardPredictions = lazy(() => import(/* webpackChunkName: "DashboardPredictions" */ "./views/dashboards/DashboardPredictions"));
const DashboardSignals = lazy(() => import(/* webpackChunkName: "DashboardSignals" */ "./views/dashboards/DashboardSignals"));
const DashboardStrategies = lazy(() => import(/* webpackChunkName: "DashboardStrategies" */ "./views/dashboards/DashboardStrategies"));
const DashboardPortfolios = lazy(() => import(/* webpackChunkName: "DashboardPortfolios" */ "./views/dashboards/DashboardPortfolios"));
const DashboardTrading = lazy(() => import(/* webpackChunkName: "DashboardTrading" */ "./views/dashboards/performance/DashboardTrading"));
const DashboardTradingHistory = lazy(() => import(/* webpackChunkName: "DashboardTradingHistory" */ "./views/dashboards/performance/TradingHistory"));
const DashboardTradingDeposits = lazy(() => import(/* webpackChunkName: "DashboardTradingDeposits" */ "./views/dashboards/performance/TradingDeposits"));
const DashboardTradingAssets = lazy(() => import(/* webpackChunkName: "DashboardTradingAssets" */ "./views/dashboards/performance/TradingAssets"));
const DashboardTradingCapital = lazy(() => import(/* webpackChunkName: "DashboardTradingCapital" */ "./views/dashboards/performance/TradingCapital"));
const DashboardAssets = lazy(() => import(/* webpackChunkName: "DashboardAssets" */ "./views/dashboards/assets/DashboardAssets"));

// Alpha Search
const AlphaStrategies = lazy(() => import(/* webpackChunkName: "AlphaStrategies" */ "./views/alpha/strategies/Alpha"))
const AlphaStrategy = lazy(() => import(/* webpackChunkName: "AlphaStrategy" */ "./views/alpha/strategies/AlphaStrategy"))
const AlphaPortfolios = lazy(() => import(/* webpackChunkName: "AlphaPortfolios" */ "./views/alpha/portfolios/Alpha"))
const AlphaPortfolio = lazy(() => import(/* webpackChunkName: "AlphaPortfolio" */ "./views/alpha/portfolios/AlphaPortfolio"))
const AlphaAssets = lazy(() => import(/* webpackChunkName: "AlphaAssets" */ "./views/alpha/trends/Alpha"))
const AlphaTemplates = lazy(() => import(/* webpackChunkName: "AlphaTemplates" */ "./views/alpha/trends/AlphaTemplates"))
const AlphaTemplate = lazy(() => import(/* webpackChunkName: "AlphaTemplate" */ "./views/alpha/trends/AlphaTemplate"))

// Pipelines
//const PipelinesHome = lazy(() => import("./views/pipelines/home/PipelinesHome"));
//const PipelinesNew = lazy(() => import(/* webpackChunkName: "PipelinesNew" */ "./views/pipelines/new/PipelinesNew"));
const PipelinesManage = lazy(() => import(/* webpackChunkName: "PipelinesManage" */ "./views/pipelines/manage/PipelinesManage"));
const PipelinesTemplates = lazy(() => import(/* webpackChunkName: "PipelinesTemplates" */ "./views/pipelines/templates/PipelinesTemplates"));
const Pipeline = lazy(() => import(/* webpackChunkName: "Pipeline" */ "./views/pipelines/details/Pipeline"));
const Template = lazy(() => import(/* webpackChunkName: "Template" */ "./views/pipelines/templates/Template"));
const PipelineEditor = lazy(() => import(/* webpackChunkName: "PipelineEditor" */ "./views/pipelines/editor/PipelineEditor"));

// Portfolios
const PortfoliosNew = lazy(() => import(/* webpackChunkName: "PortfoliosNew" */ "./views/portfolios/new/PortfoliosNew"));
const PortfoliosManage = lazy(() => import(/* webpackChunkName: "PortfoliosManage" */ "./views/portfolios/manage/PortfoliosManage"));
const Portfolio = lazy(() => import(/* webpackChunkName: "Portfolio" */ "./views/portfolios/details/Portfolio"));

// Strategies
const StrategiesManage = lazy(() => import(/* webpackChunkName: "StrategiesManage" */ "./views/strategies/manage/StrategiesManage"));
const Strategy = lazy(() => import(/* webpackChunkName: "Strategy" */ "./views/strategies/details/Strategy"));
const StrategiesNew = lazy(() => import(/* webpackChunkName: "StrategiesNew" */ "./views/strategies/new/StrategiesNew"));
const StrategyEditor = lazy(() => import(/* webpackChunkName: "StrategyEditor" */ "./views/strategies/editor/StrategyEditor"));

// Documentation
const DocIntroduction = lazy(() => import(/* webpackChunkName: "DocIntroduction" */ "./views/documentation/DocIntroduction"));
const DocStrategyHowto = lazy(() => import(/* webpackChunkName: "DocStrategies" */ "./views/documentation/DocStrategies"));
const DocPortfolioHowto = lazy(() => import(/* webpackChunkName: "DocPortfolios" */ "./views/documentation/DocPortfolios"));
const DocTradingHowto = lazy(() => import(/* webpackChunkName: "DocTrading" */ "./views/documentation/DocTrading"));
const DocTradingExchanges = lazy(() => import(/* webpackChunkName: "DocTradingExchanges" */ "./views/documentation/DocTradingExchanges"));
const DocAlphaHowTo = lazy(() => import(/* webpackChunkName: "DocAlpha" */ "./views/documentation/DocAlphaIntro"));

// Tutorials
const TutorialsAeliumIntro = lazy(() => import(/* webpackChunkName: "TutorialsAeliumIntro" */ "./views/tutorials/TutorialsAeliumIntro"));
const TutorialsQuickPortfolio = lazy(() => import(/* webpackChunkName: "TutorialsQuickPortfolio" */ "./views/tutorials/TutorialsQuickPortfolio"));
const TutorialsQuickStrategy = lazy(() => import(/* webpackChunkName: "TutorialsQuickStrategy" */ "./views/tutorials/TutorialsQuickStrategy"));
const TutorialsTradingAccounts = lazy(() => import(/* webpackChunkName: "TutorialsTradingAccounts" */ "./views/tutorials/TutorialsTradingAccounts"));

// Partners
const PartnerTest = lazy(() => import(/* webpackChunkName: "PartnerTest" */ "./views/partners/PartnerTest"));
const PartnerPortfolio = lazy(() => import(/* webpackChunkName: "PartnerPortfolio" */ "./views/partners/portfolio/PortfolioWizard"));
const PartnerStrategy = lazy(() => import(/* webpackChunkName: "PartnerStrategy" */ "./views/partners/strategy/StrategyWizard"));

const routes = [
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "/signup",
        element: <SignUp/>,
    },
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/subscription",
        element: <ProtectedRoute component={Subscription}/>,
    },
    {
        path: "/profile",
        element: <ProtectedRoute component={Profile}/>,
    },
    {
        path: "/settings",
        element: <ProtectedRoute component={Settings}/>,
    },
    {
        path: "/users",
        children: [
            {
                path: "",
                element: <ProtectedRoute component={Users}/>,
            },
            {
                path: "metrics",
                element: <ProtectedRoute component={Metrics}/>,
            },
        ]
    },
    {
        path: "/messages",
        element: <ProtectedRoute component={Messages}/>,
    },
    {
        path: "/privacy",
        element: <Privacy/>,
    },
    {
        path: "/tos",
        element: <TOS/>,
    },
    {
        path: "/notifications",
        element: <ProtectedRoute component={Notifications}/>,
    },
    {
        path: "/data",
        element: <ProtectedRoute component={UserData}/>,
    },
    {
        path: "/trading",
        children: [
            {
                path: "manage",
                element: <ProtectedRoute component={TradingAccounts}/>,
            },
            {
                path: "manage/:accountId",
                element: <ProtectedRoute component={TradingAccountDetails}/>,
            },
        ]
    },
    // SIDEBAR:
    {
        path: "/dashboards",
        children: [
            {
                path: "performance",
                children: [
                    {
                        index: true,
                        element: <ProtectedRoute component={DashboardTrading}/>,
                    },
                    {
                        path: "history",
                        element: <ProtectedRoute component={DashboardTradingHistory}/>,
                    },
                    {
                        path: "deposits",
                        element: <ProtectedRoute component={DashboardTradingDeposits}/>,
                    },
                    {
                        path: "assets",
                        element: <ProtectedRoute component={DashboardTradingAssets}/>,
                    },
                    {
                        path: "capital",
                        element: <ProtectedRoute component={DashboardTradingCapital}/>,
                    }
                ]
            },
            {
                path: "assets",
                element: <ProtectedRoute component={DashboardAssets}/>,
            },
            {
                path: "strategies",
                element: <ProtectedRoute component={DashboardStrategies}/>,
            },
            {
                path: "portfolios",
                element: <ProtectedRoute component={DashboardPortfolios}/>,
            },
            {
                path: "predictions",
                element: <ProtectedRoute component={DashboardPredictions}/>,
            },
            {
                path: "signals",
                element: <ProtectedRoute component={DashboardSignals}/>,
            },
        ]
    },
    {
        path: "/alpha",
        children: [
            {
                path: "strategies",
                element: <ProtectedRoute component={AlphaStrategies}/>,
            },
            {
                path: "strategies/:strategyId",
                element: <ProtectedRoute component={AlphaStrategy}/>,
            },
            {
                path: "portfolios",
                element: <ProtectedRoute component={AlphaPortfolios}/>,
            },
            {
                path: "portfolios/:portfolioId",
                element: <ProtectedRoute component={AlphaPortfolio}/>,
            },
            {
                path: "trends",
                element: <ProtectedRoute component={AlphaAssets}/>,
            },
            {
                path: "trends/:assetId",
                element: <ProtectedRoute component={AlphaTemplates}/>,
            },
            {
                path: "trends/:assetId/:templateId",
                element: <ProtectedRoute component={AlphaTemplate}/>,
            },
        ]
    },
    {
        path: "/strategies",
        children: [
            {
                path: "new",
                element: <ProtectedRoute component={StrategiesNew}/>,
            },
            {
                path: "manage",
                element: <ProtectedRoute component={StrategiesManage}/>,
            },
            {
                path: "manage/:strategyId",
                element: <ProtectedRoute component={Strategy}/>,
            },
            {
                path: "manage/:strategyId/editor",
                element: <ProtectedRoute component={StrategyEditor}/>,
            },
        ]
    },
    {
        path: "/portfolios",
        children: [
            {
                path: "new",
                element: <ProtectedRoute component={PortfoliosNew}/>,
            },
            {
                path: "manage",
                element: <ProtectedRoute component={PortfoliosManage}/>,
            },
            {
                path: "manage/:portfolioId",
                element: <ProtectedRoute component={Portfolio}/>,
            },
        ]
    },
    {
        path: "/pipelines",
        children: [
            {
                path: "new",
                element: <ProtectedRoute component={PipelinesTemplates}/>,
            },
            {
                path: "manage",
                element: <ProtectedRoute component={PipelinesManage}/>,
            },
            {
                path: "manage/:pipelineId",
                element: <ProtectedRoute component={Pipeline}/>,
            },
            {
                path: "manage/:pipelineId/editor",
                element: <ProtectedRoute component={PipelineEditor}/>,
            },
            {
                path: "templates",
                element: <ProtectedRoute component={PipelinesTemplates}/>,
            },
            {
                path: "templates/:templateId",
                element: <ProtectedRoute component={Template}/>,
            },
        ],
    },
    {
        path: "/docs",
        children: [
            {
                path: "introduction",
                element: <ProtectedRoute component={DocIntroduction}/>,
            },
            {
                path: "alpha",
                element: <ProtectedRoute component={DocAlphaHowTo}/>,
            },
            {
                path: "strategies",
                element: <ProtectedRoute component={DocStrategyHowto}/>,
            },
            {
                path: "portfolios",
                element: <ProtectedRoute component={DocPortfolioHowto}/>,
            },
            {
                path: "trading",
                element: <ProtectedRoute component={DocTradingHowto}/>,
            },
            {
                path: "trading/exchanges",
                element: <ProtectedRoute component={DocTradingExchanges}/>,
            }
        ]
    },
    {
        path: "/tutorials",
        children: [
            {
                path: "aelium-intro",
                element: <ProtectedRoute component={TutorialsAeliumIntro}/>,
            },
            {
                path: "quickstart-strategy",
                element: <ProtectedRoute component={TutorialsQuickStrategy}/>,
            },
            {
                path: "quickstart-portfolio",
                element: <ProtectedRoute component={TutorialsQuickPortfolio}/>,
            },
            {
                path: "quickstart-trading-accounts",
                element: <ProtectedRoute component={TutorialsTradingAccounts}/>,
            },
        ]
    },
    {
        path: "/partners",
        children: [
            {
                path: "test",
                element: <PartnerTest/>,
            },
            {
                path: "portfolio",
                element: <PartnerPortfolio/>,
            },
            {
                path: "strategy",
                element: <PartnerStrategy/>,
            },
        ]
    },
    // Catch-All
    {
        path: "*",
        element: <NotFound/>,
    },
]

export default routes;