import React from "react";
import PropTypes from "prop-types";

import useSettingsState from "../hooks/useSettingsState";

const ThemeContext = React.createContext({theme: 'dark'});

function ThemeProvider({ children }) {
  const [theme, setTheme] = useSettingsState("theme", 'dark');

  return (
    <ThemeContext.Provider value={{theme, setTheme,}}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
    children: PropTypes.any
}

export { ThemeProvider, ThemeContext };
