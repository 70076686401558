import PropTypes from "prop-types";
import React, {Fragment} from "react";
import Error from "./Error";
import {Button, Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function ErrorFallback({error, resetErrorBoundary}) {
    const navigate = useNavigate();

    function reset() {
        resetErrorBoundary()
        navigate('/')
    }

    return (
        <Fragment>
        <Error message={"GURU MEDITATION: something really bad happened. Please contact the developers at support@aelium.io"}/>
            <Container>
                <div className="float-end">
                    <Button id="edit-profile" variant="primary" onClick={reset}>
                        Beam Me Up Scotty
                    </Button>
                </div>

                <h5>Error Type:</h5>
                <pre>{error.name}</pre>

                <h5>Error Message:</h5>
                <pre>{error.message}</pre>

                <h5>Stack Trace:</h5>
                <pre>{error.stack.split("\n").slice(0, 10).join("\n")}</pre>

            </Container>
        </Fragment>
    )
}

ErrorFallback.propTypes = {
    error: PropTypes.any,
    resetErrorBoundary: PropTypes.func,
};

export default ErrorFallback