import {useImmutableGetDataWithOrWithoutToken} from "../network/network";


function useLicense() {
    const { data, isLoading, error } = useImmutableGetDataWithOrWithoutToken('/user/license/')

    return {
        license: data,
        isLoading: isLoading,
        error: error,
    }
}

export {
    useLicense,
};
