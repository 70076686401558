import React from "react";
import PropTypes from "prop-types";
import {Bell} from "react-feather";

const Error = ({message}) => (
    <div className="alert alert-danger alert-outline-coloured" role="alert">
        <div className="alert-icon">
            <Bell size={16}/>
        </div>
        <div id="error" className="alert-message">
            <strong>Error:</strong> {message}
        </div>
    </div>
);

Error.propTypes = {
    message: PropTypes.string
};

export default Error;