import {useGetDataWithToken, useGetWithToken} from "../network/network";
import { useSWRConfig } from 'swr'

function useUnreadNotificationsAndCount() {
    const { data: count, isLoading: countLoading, error: countError } = useGetDataWithToken('/notifications/count/?unread=true')
    const { data: notifications, isLoading: notificationsLoading, error: notificationsError } = useGetDataWithToken(() => count.count > 0 ? '/notifications/?unread=true&limit=5' : null)

    return {
        count: count ? count.count : count,
        notifications : notifications,
        isLoading: countLoading || (!countError && count.count > 0 && notificationsLoading),
        error: countError || notificationsError
    }
}

function useUnreadNotificationsCount() {
    const { data, isLoading, error } = useGetDataWithToken('/notifications/count/?unread=true')

    return {
        count: data ? data.count : data,
        isLoading: isLoading,
        error: error
    }
}

function useUnreadNotifications() {
    const { data, isLoading, error } = useGetDataWithToken('/notifications/?unread=true')

    return {
        notifications: data,
        isLoading: isLoading,
        error: error
    }
}

function useNotificationsCount() {
    const { data, isLoading, error } = useGetDataWithToken('/notifications/count/')

    return {
        count: data ? data.count : data,
        isLoading: isLoading,
        error: error
    }
}


function useNotifications() {
    const { data, isLoading, error } = useGetDataWithToken('/notifications/')

    return {
        notifications: data,
        isLoading: isLoading,
        error: error
    }
}

function useNotification(id) {
    const { data, isLoading, error } = useGetDataWithToken(`/notifications/${id}/`)
    const { mutate } = useSWRConfig()

    return {
        notification: data,
        isLoading: isLoading,
        error: error,
        invalidateCache: function () {
            // invalidate the unread count
            mutate('/notifications/count/?unread=true')
        }
    }
}

function useMarkAllNotificationsAsRead() {
    const { getWithToken } = useGetWithToken('/notifications/mark-all-as-read/')
    const { mutate } = useSWRConfig()

    return {
        markAllNotificationsAsRead: getWithToken,
        invalidateCache: function () {
            // force the reload of counters and list
            mutate('/notifications/count/?unread=true')
            mutate("/notifications/")
        }
    }
}

function useTestWebPushNotification() {
    const { getWithToken } = useGetWithToken('/notifications/test-webpush/')

    return {
        testWebPushNotification: getWithToken,
    }
}

export {
    useUnreadNotificationsAndCount,
    useUnreadNotificationsCount,
    useUnreadNotifications,
    useNotificationsCount,
    useNotifications,
    useNotification,
    useMarkAllNotificationsAsRead,
    useTestWebPushNotification,
};
