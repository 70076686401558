
function checkRole(profile, role) {

    if (!profile)
        return false

    if (!profile.auth0)
        return false

    if (!profile.auth0['https://app.aelium.io/roles'])
        return false

    return profile.auth0['https://app.aelium.io/roles'].includes(role)
}

export default checkRole