import React, {useEffect} from "react";
import {Mail} from "react-feather";
import {NavbarDropdown, NavbarDropdownItem} from "./NavbarDropdown";
import {useUnreadMessagesAndCount} from "../../utils/hooks/messages";

import Avatar from "../../assets/default-avatar.png";
import {toast} from "react-hot-toast";
import {Col, Row, Toast} from "react-bootstrap";
import TimeAgo from "react-timeago";
import {useNavigate} from "react-router-dom";

const Messages = () => {
    const {count, messages, isLoading, error} = useUnreadMessagesAndCount()
    const navigate = useNavigate()

    useEffect(() => {
        /* process the notifications in reverse order, since the data is ordered by date descending */
        messages && Array.from(messages).reverse().map((item) => {
            /*
                we store the last message_id in the localstorage, were we are exploiting
                the fact that the id is autoincrement in the database
             */
            if (localStorage.getItem('last_message_id') < item.id) {
                toast.custom(
                    (t) => (
                        <Toast onClose={() => toast.dismiss(t.id)} show={t.visible}>
                            <Toast.Header>
                                <div className="me-2"><Mail/></div>
                                <strong className="me-auto"/>
                                <small><TimeAgo date={item.timestamp}/></small>
                            </Toast.Header>
                            <Toast.Body>
                                <Row className="align-items-center g-0" style={{cursor: "pointer"}}
                                     onClick={() => {
                                         toast.dismiss(t.id);
                                         navigate(`/messages?id=${item.id}`)
                                     }}>
                                    <Col xs={2}>{getAvatar(item)}</Col>
                                    <Col xs={10} className="ps-2">
                                        <div className="text-dark">{item.sender_nickname}</div>
                                        <div className="text-muted small mt-1">
                                            {item.subject}
                                        </div>
                                    </Col>
                                </Row>
                            </Toast.Body>
                        </Toast>
                    ),
                    {id: item.id, duration: 5000}
                )
                localStorage.setItem('last_message_id', item.id)
            }

            return null
        })
    }, [messages, navigate])

    if (isLoading) return <NavbarDropdown
        id="messages"
        header="Loading Messages"
        link="/messages"
        icon={Mail}/>

    if (error) return <NavbarDropdown
        id="messages"
        header={"Error Loading Messages: " + error}
        link="/messages"
        icon={Mail}/>

    function getAvatar(item) {
        return <img
            className="avatar img-fluid rounded-circle"
            src={item.sender_avatar || Avatar}
            alt={item.sender_nickname}
        />;
    }

    return (
        <NavbarDropdown
            id="messages"
            header="Unread Messages"
            header_when_empty="Open Inbox"
            link="/messages"
            icon={Mail}
            count={count}
            showBadge={count > 0}
        >
            {/* entries in the drop down menu should be ordered by date descending (same as the received data) */
                messages && messages.map((item, key) => {
                    return (
                        <NavbarDropdownItem
                            key={key}
                            icon={getAvatar(item)}
                            title={item.sender_nickname}
                            description={item.subject}
                            time={item.timestamp}
                            link={"/messages?id=" + item.id}
                            spacing
                        />
                    );
                })}
        </NavbarDropdown>
    )
};

export default Messages;
