import React, {Fragment} from "react";
import {Button, Nav} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";

const SignUp = () => {
    const navigate = useNavigate();
    const location = useLocation();

    if (location.pathname === '/signup') {
        return <Fragment/>
    }

    return (
        <Nav.Item>
            <Button id="signup" variant="outline-primary" className="me-1 px-2" onClick={() => navigate('/signup')}>
                Sign up
            </Button>
        </Nav.Item>
    )
};

export default SignUp;