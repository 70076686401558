import {useAuth0} from "@auth0/auth0-react";
import {useCheckWithToken, useGetDataWithToken, usePutWithToken} from "../network/network";

function useProfile() {
    const {user} = useAuth0();
    const { data, isLoading, error } = useGetDataWithToken('/user/profile/')

    let returnData = {}

    if (data) {
        // deep copy data from our db
        returnData.db = JSON.parse(JSON.stringify(data))

        // append the full auth0 metadata
        returnData.auth0 = user

        // no name in the DB: use the auth0 name
        returnData.name = (data.name.trim() === '') ? user.name : data.name
        // always use the name from auth0
        //returnData.name = user.name

        // always use the nickname from the db, no need to modify
        returnData.nickname = data.nickname

        // no avatar in the db, use the auth0 one
        returnData.avatar = (data.avatar.trim() === '') ? user.picture : data.avatar

        // always use the email from auth0
        returnData.email = user.email

        // when the user registered the first time
        returnData.registered = data.created_at
    }

    return {
        profile: returnData,
        isLoading: isLoading,
        error: error
    }
}

function useUpdateProfile() {
    const {getAccessTokenSilently} = useAuth0();
    const { putWithToken } = usePutWithToken('/user/profile/')

    return {
        updateProfile: putWithToken,
        invalidateProfileToken: function () {
            getAccessTokenSilently({ cacheMode: 'off' })
        }
    }
}

function useCheckNicknameProfile() {
    const { checkWithToken } = useCheckWithToken('/user/profile/check_nickname?nickname=')

    return {
        checkNickname: checkWithToken
    }
}

function useProfileStats() {
    const { data, isLoading, error } = useGetDataWithToken('/user/profile/stats/')

    return {
        stats: data,
        isLoading: isLoading,
        error: error
    }
}

export {
    useProfile,
    useUpdateProfile,
    useCheckNicknameProfile,
    useProfileStats
};
