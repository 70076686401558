import React, {Fragment, Suspense} from "react";
import {useLocation, useRoutes} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {SWRConfig} from "swr";
import {createTheme} from "react-data-table-component";

import Loading from "./components/Loading";
import SideBar from "./components/sidebar/Sidebar";
import NavBar from "./components/navbar/NavBar";
import Footer from "./components/Footer";

import Auth0Error from "./components/Auth0Error";
import Auth0Approval from "./components/Auth0Approval";

import {LicenseWarning} from "./views/profile/License";

import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "./components/ErrorFallback";


// the theme css (appstack.bootlab.io)
// use this to dynamically compile it at runtime. otherwise we use the css links in the index.html
//import './App.scss'

import routes from "./routes"

// this is for the react-data-tables theme
createTheme('dark', {
    background: {
        default: 'transparent',
    },
});

function App() {
    const routing = useRoutes(routes);
    const location = useLocation();
    // prop used to hide / show the sidebar
    const [toggleSidebar, setToggleSidebar] = React.useState(true)

    // for error handling reset
    const [errorHandler, setErrorHandler] = React.useState(false)

    // authentication stuff
    const {isLoading, error, isAuthenticated} = useAuth0();

    if (error) {
        if (error.message.startsWith("Your account needs to be approved")) {
            return <Auth0Approval message={error.message}/>
        } else if (error.message.startsWith("Please verify your email")) {
            return <Auth0Approval message={error.message}/>
        } else {
            return <Auth0Error message={error.message}/>;
        }
    }

    if (isLoading) {
        return <Loading/>;
    }

    if (location.pathname === '/signup') {
        return (
            <Fragment>
                {routing}
            </Fragment>
        )
    }

    // webview for partners integrations
    if (location.pathname.includes('/partners/')) {
        return (
            <SWRConfig value={{onError: (error, key) => {console.log(`Global Error Handler: [${key}] ${error}`)} }}>
                <div className="main">
                    <main className="content">
                        <div className="container-fluid p-0">
                            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {setErrorHandler(e => !e)}} resetKeys={[errorHandler]}>
                                <Suspense fallback={<Loading/>}>
                                    {routing}
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                    </main>
                </div>
            </SWRConfig>
        )
    }

    return (
        <SWRConfig value={{
            onError: (error, key) => {
                // we can log the error to Sentry here
                console.log(`Global Error Handler: [${key}] ${error}`)
            }
        }}>

            <div className="wrapper">

                {/* sidebar on the left */}
                { isAuthenticated && (
                    <SideBar isOpen={toggleSidebar}/>
                )}

                {/* main container for all the pages (views) */}
                <div className="main">
                    <NavBar toggleSidebar={toggleSidebar} setToggleSidebar={setToggleSidebar}/>

                    <main className="content">
                        <div className="container-fluid p-0">
                            {
                                isAuthenticated && (
                                    <LicenseWarning/>
                                )
                            }
                            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {setErrorHandler(e => !e)}} resetKeys={[errorHandler]}>
                                <Suspense fallback={<Loading/>}>
                                    {routing}
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                    </main>

                    <Footer isAuthenticated={isAuthenticated}/>
                </div>
            </div>
        </SWRConfig>
    );
}

export default App;
